<template>
  <b-card>
    <b-row v-if="showAccommodationSelector" class="justify-content-end mb-4">
      <b-col cols="12" sm="5" md="4" lg="3">
        <v-select
          v-model="selectedAccommodation"
          class="accommodation-select"
          placeholder="Selecciona un allotjament"
          :options="accommodationOptions"
          :reduce="(option) => option.value"
          :searchable="false"
          :clearable="false"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <calendar
          :bookings="accommodationBookings"
          :availabilities="accommodationAvailabilities"
          :owner-rates="accommodationOwnerRates"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCard, BCol } from "bootstrap-vue";
import Calendar from "@/views/bookings/calendar/components/Calendar.vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    Calendar,
  },
  data() {
    return {
      selectedAccommodation: null,
    };
  },
  computed: {
    owner() {
      return this.$store.getters["owner/owner"];
    },
    accommodations() {
      return this.$store.getters["owner/accommodations"];
    },
    accommodationOptions() {
      if (!this.accommodations.length) return [];
      return this.accommodations.map((accommodation) => ({
        label: accommodation.name,
        value: accommodation.uuid,
      }));
    },
    accommodationOwnerRates() {
      if (!this.selectedAccommodation) return [];
      const accommodationsOwnerRates =
        this.$store.getters["owner/accommodationRates"];
      let foundRates = [];
      Object.entries(accommodationsOwnerRates).forEach((entry) => {
        const [accommodationUuid, accommodationRates] = entry;
        if (accommodationUuid === this.selectedAccommodation) {
          foundRates = foundRates.concat(accommodationRates);
        }
      });
      return foundRates;
    },
    loadingAccommodation() {
      return this.$store.getters["accommodation/loading"];
    },
    accommodationBookings() {
      return this.$store.getters["accommodation/bookings"];
    },
    accommodationAvailabilities() {
      return this.$store.getters["accommodation/availabilities"];
    },
    showAccommodationSelector() {
      return this.accommodations.length > 1;
    },
    uiCalendarAccommodation() {
      return this.$store.getters["ui/calendarAccommodation"];
    },
  },
  watch: {
    selectedAccommodation(uuid) {
      this.$store.dispatch("ui/setCalendarAccommodation", uuid);
      if (uuid && !this.loadingAccommodation) this.fetchAccommodation();
    },
    accommodations() {
      this.initSelectedAccommodation();
    },
  },
  created() {
    if (!this.owner) this.fetchOwner();
    this.initSelectedAccommodation();
  },
  methods: {
    initSelectedAccommodation() {
      if (!this.accommodations.length) return;
      this.selectedAccommodation =
        this.uiCalendarAccommodation ?? this.accommodations[0].uuid;
    },
    fetchOwner() {
      const userData = JSON.parse(
        localStorage.getItem("foravilaOwnersUserData")
      );

      const ownerUuid = userData?.owner?.uuid || null;
      if (!ownerUuid) return;

      this.$store.dispatch("app/setLoading", true);

      this.$store.dispatch("owner/fetchOwner", ownerUuid).catch(() => {});
    },
    async fetchAccommodation() {
      if (!this.selectedAccommodation) return;

      this.$store.dispatch("app/setLoading", true);

      const accommodation = await this.$store
        .dispatch(
          "accommodation/fetchAccommodation",
          this.selectedAccommodation
        )
        .then((accommodationResponse) => accommodationResponse);

      if (!accommodation?.uuid) {
        this.$store.dispatch("app/setLoading", false);
        return;
      }

      const promises = [
        this.$store.dispatch(
          "accommodation/fetchAvailabilities",
          accommodation.uuid
        ),
        this.$store.dispatch("accommodation/fetchBookings", accommodation.uuid),
        this.$store.dispatch(
          "accommodation/fetchDatesLocks",
          accommodation.uuid
        ),
      ];

      Promise.all(promises)
        .catch(() => {})
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
